export default {
  "about-us": {
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velkommen til Lighthousing.io! Vi er dedikeret til at levere innovative løsninger, der hjælper dig med at navigere i dit digitale landskab med lethed. Vores team brænder for teknologi, er forpligtet til ekspertise og er altid klar til at yde det ekstra for vores kunder. Mød folkene bag Lighthousing.io:"])},
    "jacob": {
      "bio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Med over to årtiers erfaring i teknologibranchen har Jacob Suñol været i front inden for digitale sundhedsinnovationer. Efter at have arbejdet med industrigiganter som Abbott og Roche, bringer Jacob en rigdom af viden og ekspertise til Lighthousing.io. Specialiseret inden for digital sundhed, brænder Jacob for at udnytte teknologi til at forbedre sundhedsresultater. En stærk baggrund i datalogi, antropologi og menneskelig evolution giver et unikt perspektiv, der driver Jacobs tilgang til problemløsning og innovation. Uden for arbejdet elsker Jacob at bygge ting, spille videospil og stå på ski. Jacob er også fortaler for en sund livsstil og omfavner en glutenfri, kulhydratfattig livsstil. Hos Lighthousing.io er Jacob forpligtet til at fremme en kultur af kreativitet, samarbejde og løbende forbedringer. Jacob tror på teknologiens kraft til at forvandle liv og er dedikeret til at gøre en positiv indvirkning gennem hvert projekt."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundlægger"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Om Os"])}
  },
  "contact": {
    "contact-form": {
      "agree-privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeg accepterer"])},
      "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Din besked"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Din e-mail"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit navn"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send besked"])},
      "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit emne..."])}
    },
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vi trives, når vi kommer med innovative idéer, men vi forstår også, at et smart koncept bør støttes med målbare resultater."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt os"])}
  },
  "features": {
    "p1": {
      "action-btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Få mere at vide"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vores Star Lab hjælper med at bringe enhver idé til live. Uanset kompleksitetsniveauet, hvis det kan påvirke millioner af mennesker, vil vi få det til at ske."])},
      "points": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vi lægger en stor indsats i at tænke."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kodning er en kunst."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gode produkter er designet til slutbrugeren."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iterér over og over 10.000 gange."])},
        
      ],
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Et digitalt atelier, der skaber moderne og engagerende online oplevelser"])}
    },
    "p2": {
      "action-btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt os"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har en idé, der vil påvirke millioner af mennesker, kontakt os. Lad os arbejde sammen for at få det til at ske."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star Lab, et laboratorium for drømmere"])}
    }
  },
  "footer": {
    "contact-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt os"])},
    "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oplysninger"])},
    "subscribe": {
      "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hver måned sender vi kurateret indhold for at hjælpe på din digitale rejse."])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonner"])}
    }
  },
  "home": {
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vi giver lys til virksomheder og enkeltpersoner ved at hjælpe med at bruge forstyrrende teknologier. Vi kan hjælpe med ethvert skridt i produktudvikling, fra ideering til udvikling. Vi kan også hjælpe med at forbedre dit interne team ved at træne, træne og ansætte. Vi specialiserer os i forstyrrende produkter, der hjælper med at påvirke millioner af mennesker positivt."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vi hjælper virksomheder med at lancere deres fantastiske produkter"])},
    "video-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RnDC9MXSqCY"])}
  },
  "section": {
    "Star Lab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star Lab"])},
    "about-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Om Os"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt"])},
    "cookies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politik om cookies"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hjem"])},
    "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privatlivspolitik"])},
    "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tjenester"])},
    "starlab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star Lab"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
    "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vilkår"])}
  },
  "services": {
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vi fremstiller produkter, forretning og teknologi for at tilføre værdi for vores kunder og deres kunder."])},
    "list": {
      "s1": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vi er eksperter, der bygger digitale sundhedsløsninger til branchen. Fra undfangelse til produktion. Vi kan samarbejde med dit team, oprette et uafhængigt team eller forbedre dine evner. Vi arbejder på SAMD klasse IIb."])},
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pe-7s-phone"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digital sundhed"])}
      },
      "s2": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vi kombinerer vores ekspertise inden for teknologi med den rette arkitektur og innovation. Vi specialiserer os i Javascript, Cloud, web og mobil udvikling. Vi arbejder på regulerede miljøer, der følger din QMS eller anvender vores."])},
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pe-7s-science"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teknologi"])}
      },
      "s3": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vil du køre et dataprojekt. Vi kan give dig enhver hjælp, fra at hjælpe på forretningsproblemet, valg af datasæt, kuration, analyse, modellering, visualisering. Vi kan også hjælpe dig med privatlivets fred emner i det meste alle dele af verden."])},
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pe-7s-graph1"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ML og kunstig intelligens"])}
      },
      "s4": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vil du øge forretningsmulighederne ved at bruge digital? Vi leverer tjenester omkring lederskab inden for innovation, strategiudvikling, forretningsarkitektur og organisatorisk transformation."])},
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pe-7s-culture"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forretningsarkitektur"])}
      },
      "s5": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For virksomheder, der oplever ledelseshuller (CIO, CTO, CDO, CSO, DEO), kan vi tilbyde midlertidige ledere til at træde til for at få en virksomhed på sporet og i sidste ende ansætte deres faste afløser."])},
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pe-7s-helm"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foreløbige execs"])}
      },
      "s6": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har en idé, du er ekspert i et område og ønsker at innovere? Vi er her for at hjælpe. Vi springer også flere løsninger fra vores StarLab."])},
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pe-7s-plane"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startaccelerator"])}
      },
      "s7": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vi designer til at påvirke mennesker. Ved at sætte folk i første række opbygger vi oplevelser, der vil omdefinere din branche. Fra papir, musik, til VR eksisterer grænsen ikke."])},
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pe-7s-compass"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundeoplevelse"])}
      }
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vores tjenester"])}
  },
  "support": {
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvis du har problemer med at få adgang til et Lighthousing produkt, er der en chance for, at vi i øjeblikket oplever et midlertidigt problem. Du er dog velkommen til at sende os en besked, og vi vil yde den nødvendige hjælp. "])},
    "support-form": {
      "agree-privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeg accepterer"])},
      "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beskriv dit problem..."])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Din e-mail"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit navn"])},
      "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produktnavn..."])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send besked"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvordan kan vi hjælpe dig?"])}
  }
}