export default {
  "about-us": {
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Bienvenidos a Lighthousing.io! Nos dedicamos a proporcionar soluciones innovadoras para ayudarte a navegar tu paisaje digital con facilidad. Nuestro equipo es apasionado por la tecnología, comprometido con la excelencia y siempre listo para dar lo mejor de sí para nuestros clientes. Conoce a las personas detrás de Lighthousing.io:"])},
    "jacob": {
      "bio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Con más de dos décadas de experiencia en la industria tecnológica, Jacob Suñol ha estado a la vanguardia de las innovaciones en salud digital. Habiendo trabajado con gigantes de la industria como Abbott y Roche, Jacob aporta una gran cantidad de conocimientos y experiencia a Lighthousing.io. Especializado en salud digital, Jacob es apasionado por aprovechar la tecnología para mejorar los resultados de salud. Una sólida formación en informática, antropología y evolución humana proporciona una perspectiva única que impulsa el enfoque de Jacob para resolver problemas e innovar. Fuera del trabajo, a Jacob le encanta construir cosas, jugar videojuegos y esquiar. Jacob también es un defensor de la vida saludable, adoptando un estilo de vida sin gluten y bajo en carbohidratos. En Lighthousing.io, Jacob está comprometido a fomentar una cultura de creatividad, colaboración y mejora continua. Jacob cree en el poder de la tecnología para transformar vidas y está dedicado a hacer un impacto positivo en cada proyecto."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fundador"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobre Nosotros"])}
  },
  "contact": {
    "contact-form": {
      "agree-privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acepto la"])},
      "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu mensaje..."])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu correo electrónico"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu nombre"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar mensaje"])},
      "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu asunto..."])}
    },
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nos encanta cuando se nos plantean ideas innovadoras, pero también entendemos que un concepto inteligente debe ser iniciado con resultados cuantificables."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ponerse en contacto"])}
  },
  "features": {
    "p1": {
      "action-btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Más información"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuestro Star Lab ayuda a dar vida a cualquier idea. No importa el nivel de complejidad, si puede afectar a millones de personas lo haremos realidad."])},
      "points": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ponemos mucho esfuerzo en idear."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programar es un arte."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los buenos productos están diseñados para el usuario final."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iterar una y más de 10.000 veces."])},
        
      ],
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un taller digital que crea experiencias digitales modernas y atractivas"])}
    },
    "p2": {
      "action-btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contáctenos"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usted tiene una idea que afectará a millones de personas, póngase en contacto con nosotros. Trabajemos juntos para que suceda."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star Lab, un laboratorio para soñadores"])}
    }
  },
  "footer": {
    "contact-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contáctenos"])},
    "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información"])},
    "subscribe": {
      "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cada mes enviaremos contenido revisado para ayudarle en su viaje digital."])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suscribirse"])}
    }
  },
  "home": {
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proporcionamos luz a empresas e individuos ayudando a usar tecnologías disruptivas. Podemos ayudar con cualquier paso del desarrollo de productos, desde la ideación hasta el desarrollo. También podemos ayudar a mejorar su equipo interno al entrenar, entrenar y contratar. Nos especializamos en productos disruptivos que ayudan a afectar positivamente a millones de personas."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ayudamos a las empresas a lanzar sus increíbles productos"])},
    "video-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RnDC9MXSqCY"])}
  },
  "section": {
    "Star Lab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star Lab"])},
    "about-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobre nosotros"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacto"])},
    "cookies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Política de cookies"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicio"])},
    "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Política de privacidad"])},
    "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicios"])},
    "starlab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star Lab"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soporte"])},
    "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Términos"])}
  },
  "services": {
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabricamos productos, negocios y tecnología para agregar valor a nuestros clientes y sus clientes."])},
    "list": {
      "s1": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Somos expertos en la construcción de soluciones de salud digital para la industria. De la concepción a la producción. Podemos trabajar con su equipo, configurar un equipo independiente o mejorar sus capacidades. Trabajamos en SaMD clase IIb."])},
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pe-7s-phone"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digital Health"])}
      },
      "s2": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Combinamos nuestra experiencia en tecnología con la arquitectura y la innovación adecuadas. Nos especializamos en Javascript, Cloud, desarrollo web y móvil. Trabajamos en entornos regulados siguiendo su QMS o aplicando el nuestro."])},
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pe-7s-science"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tecnología"])}
      },
      "s3": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desea conducir un proyecto de datos. Podemos proporcionarle cualquier ayuda, desde ayuda en el tema empresarial, selección de conjuntos de datos, curación, análisis, modelado, visualización. Podemos ayudarle también con los temas de privacidad en casi cualquier parte del mundo."])},
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pe-7s-graph1"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ML e Inteligencia Artificial"])}
      },
      "s4": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Desea aumentar las oportunidades de negocio mediante el uso digital? Proporcionamos servicios en torno al liderazgo en innovación, desarrollo de estrategias, arquitectura empresarial y transformación organizacional."])},
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pe-7s-culture"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arquitectura"])}
      },
      "s5": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para las empresas que están experimentando brechas de liderazgo (CIO, CTO, CDO, CSO, DEO), podemos proporcionar a ejecutivos interinos que intervengan para que una empresa esté en camino, en última instancia contratando su reemplazo permanente."])},
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pe-7s-helm"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ejecutivos interinos"])}
      },
      "s6": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Tienes una idea, eres un experto en un área y quieres innovar? Estamos aquí para ayudar. También ponemos en marcha múltiples soluciones de nuestro Star Lab."])},
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pe-7s-plane"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acelerador de empresas"])}
      },
      "s7": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diseñamos para impactar a los humanos. Al poner a las personas en primer lugar, construimos experiencias que redefinirán su industria. Desde el papel, la música, hasta la realidad virtual, el límite no existe."])},
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pe-7s-compass"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experiencia del cliente"])}
      }
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuestros Servicios"])}
  },
  "support": {
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si tienes problemas para acceder a un producto Lighthousing, existe la posibilidad de que estemos experimentando un problema temporal. Sin embargo, no dude en enviarnos un mensaje y le proporcionaremos la ayuda necesaria. "])},
    "support-form": {
      "agree-privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acepto la"])},
      "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Describa su problema..."])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu correo electrónico"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu nombre"])},
      "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre del producto..."])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar mensaje"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿En qué podemos ayudarle?"])}
  }
}